import React from "react"
import styled from "styled-components"
import { H3DarkBlue, medWrapper } from "../../styles/helpers"

const Video = ({ data }) => {
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h2>{data.videoTitle}</h2>
        </div>
        <div
          className="video"
          dangerouslySetInnerHTML={{ __html: data.videoEmbed }}
        />
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;

    h2 {
      ${H3DarkBlue};
    }
  }

  .video {
    width: 100%;

    iframe {
      width: 100% !important;
      height: calc(100vw * (315 / 560)) !important;
      max-height: 94.4rem !important;
    }
  }
`

export default Video
